<template>
  <svg
    version="1.1"
    id="arrow"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 96 96"
    alt="Arrow pointing downwards - click here to scroll to next page"
    @click="scroll"
  >
    <g>
      <path
        d="M48 62c-0.332 0-0.66-0.084-0.956-0.248l-44-24c-0.968-0.524-1.328-1.74-0.796-2.712 0.528-0.968
                        1.752-1.328 2.712-0.8l43.040 23.48 43.044-23.48c0.968-0.528 2.188-0.168 2.716 0.8 0.528 0.972 0.172
                        2.184-0.8 2.712l-44 24c-0.3 0.164-0.628 0.248-0.96 0.248z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {
  name: 'NextScreenArrow',
  setup: () => {
    const scroll = () => {
      const target = document.getElementById('trailer')
      target?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    return {
      scroll,
    }
  },
}
</script>

<style scoped lang="scss">
@media print {
  #arrow {
    display: none;
  }
}
</style>
