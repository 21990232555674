
export default {
  name: 'NextScreenArrow',
  setup: () => {
    const scroll = () => {
      const target = document.getElementById('trailer')
      target?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    return {
      scroll,
    }
  },
}
