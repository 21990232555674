<template>
  <button v-if="!href" :class="classes" @click="$emit('click', $event)">
    <slot></slot>
  </button>
  <a
    v-if="href"
    :class="classes"
    :href="href"
    :target="target"
    @click="$emit($event)"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent, Prop, computed } from 'vue'

export default defineComponent({
  name: 'Button',
  props: {
    outlined: Boolean,
    color: {
      default: 'white',
    } as Prop<'white' | 'black' | 'red'>,
    href: String,
    class: String,
    target: String,
  },
  emits: ['click'],
  setup: props => {
    const classes = computed(() => {
      const result: string[] = [
        'button',
        props.color ?? 'white',
        props.class ?? '',
      ]

      if (props.outlined) {
        result.push('outlined')
      }

      return result.join(' ')
    })
    return {
      classes: classes,
    }
  },
})
</script>

<style lang="scss" scoped>
.button {
  // variables will be defined later in classes
  color: var(--primary-color);
  background: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  padding: 0.6em 1.5em;
  border-radius: 10px;
  line-height: 1;
  text-align: center;
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: none;

  &:active,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }

  &.white {
    --primary-color: var(--black);
    --secondary-color: var(--white);
  }

  &.black {
    --primary-color: var(--white);
    --secondary-color: var(--black);
  }

  &.red {
    --primary-color: var(--white);
    --secondary-color: var(--red);
  }

  &.outlined {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    background: transparent;
  }
}
</style>
