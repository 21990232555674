<template>
  <div class="plays">
    <h2>Stücke</h2>
    <PlayCard
      more-link="./bunbury"
      image-url="/bunbury_thumbnail.jpg"
      title="Bunbury"
      video-link="https://youtu.be/yjfnVfG78rE"
    >
      <p>
        Seien Sie mal Ernst: Fällt es Ihnen auch manchmal schwer Ernst zu sein?
        Ernst in einer anderen Stadt, in einem anderen Umfeld? Ernst bei Ihrem
        Bachelor? Ernst in der Ernsthaftigkeit?
      </p>

      <p>
        „Bunbury - Die Bedeutung, Ernst zu sein“ von Oscar Wilde wird Ihnen bei
        diesen Fragen mit dem gewissen Ernst zur Seite stehen. Wir leben in
        einer Zeit der Ernsthaftigkeit, also lassen Sie uns gemeinsam damit
        aufhören, Ernst zu sein.
      </p>
    </PlayCard>
    <PlayCard
      more-link="./krug"
      image-url="/krug_thumbnail.png"
      title="Der zerbrochene Krug"
      video-link="https://youtu.be/p-iHh7Qfa6M"
    >
      <p>
        Sehr geehrte Nachbarn, liebe Freunde und Verwandte, verehrter Herr
        Bürgermeister,
      </p>

      <p>
        mir schmerzt es sehr, verkünden zu müssen, dass gestern Abend das
        Undenkbare eingetreten ist: Mein Krug wurde zertrümmert, und zwar
        offensichtlich von dem Drecksbengel Ruprecht Tümpel.
      </p>

      <p>
        Sie sind alle herzlich eingeladen, sich anzusehen, wie ich ihn vor
        Gericht zur Verantwortung ziehe.
      </p>
    </PlayCard>
    <PlayCard
      more-link="./diener"
      image-url="/diener_thumbnail.webp"
      title="Diener zweier Herren"
      video-link="https://youtu.be/y8KUV1Pqtzc"
    >
      <p>Buongiorno!</p>
      <p>
        Ich armer, gebeutelter Diener suche sehr bald eine neue Beschäftigung!
        Ich bin fleißig! Ich bin stark! Ich bin schnell! Aber mein aktueller
        Herr bezahlt nicht gut! Er weiß nicht, wann man gefälligst zu essen hat!
      </p>
      <p>
        Schauen wir mal, ob ich wohl im Musischen Zentrum einen Neuen Herren
        finden kann. Ich habe die Ehre mit aller Hochachtung euer ergebenster
        Diener und Freund zu sein!
      </p>
      <p>Truffaldino Battacchio aus Bergamo</p>
      <template v-slot:extra-controls>
        <div>
          <Button
            class="extra-button"
            outlined
            href="./minecraft"
            color="black"
          >
            Proben in Minecraft
          </Button>
        </div>
      </template>
    </PlayCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PlayCard from '@/views/Beta/PlayCard.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Plays',
  components: { Button, PlayCard },
})
</script>

<style lang="scss" scoped>
@import '../../mixins';

.plays {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 100px;
  background-color: var(--dark-gray);
  align-items: center;

  @include respond-below(md) {
    padding: 15vw;
  }
}

h2 {
  font-size: 48px;
  margin: 0;
}

/deep/ .extra-button {
  font-weight: bold;
}
</style>
