
import { defineComponent, Prop, computed } from 'vue'

export default defineComponent({
  name: 'Button',
  props: {
    outlined: Boolean,
    color: {
      default: 'white',
    } as Prop<'white' | 'black' | 'red'>,
    href: String,
    class: String,
    target: String,
  },
  emits: ['click'],
  setup: props => {
    const classes = computed(() => {
      const result: string[] = [
        'button',
        props.color ?? 'white',
        props.class ?? '',
      ]

      if (props.outlined) {
        result.push('outlined')
      }

      return result.join(' ')
    })
    return {
      classes: classes,
    }
  },
})
