
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'YouTubeLogo',
  props: {
    rectangleColor: String,
    triangleColor: String,
  },
})
