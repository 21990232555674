<template>
  <StartScreen />
  <div id="trailer">
    <!--					https://www.youtube.com/watch?v=xsSRHFn-SgQ&feature=youtu.be-->
    <iframe
      src="https://www.youtube-nocookie.com/embed/p-iHh7Qfa6M"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
  <InfoScreen />
</template>

<script lang="ts">
import StartScreen from '@/views/Krug/StartScreen.vue'
import InfoScreen from '@/views/Krug/InfoScreen.vue'

export default {
  name: 'Krug',
  components: { InfoScreen, StartScreen },
}
</script>

<style lang="scss">
@font-face {
  font-family: Pristina;
  src: url('/public/PRISTINA.TTF');
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #000000;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}
</style>

<style lang="scss" scoped>
/*
 * TRAILER
 */

#trailer {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
}

#trailer iframe {
  width: 100%;
  height: 100%;
}

@media print {
  html,
  body {
    background-color: #000000 !important;
  }

  #trailer {
    display: none;
  }

  h1 {
    font-size: 4.3em;
  }
}
</style>
