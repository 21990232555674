<template>
  <Screen>
    <h2 class="pre-title">Der <em>ChaosTRUB</em> präsentiert:</h2>
    <h1 aria-label="Bunbury" />
    <h2 class="subtitle">
      Die Bedeutung, Ernst zu sein
      <aside>nach Oscar Wilde</aside>
    </h2>
    <img
      src="Christian.png"
      id="christian"
      alt="A main character sitting in a black leather bag"
    />
    <NextScreenArrow class="arrow" />
    <em id="comingSoon">Diesen Freitag und Samstag im MZ!</em>
  </Screen>
</template>
<script lang="ts">
import NextScreenArrow from '@/components/NextScreenArrow.vue'
import Screen from '@/components/Screen.vue'

export default {
  name: 'StartScreen',
  components: { NextScreenArrow, Screen },
}
</script>
<style lang="scss" scoped>
.screen {
  height: 100vh;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 5vh 20vh 5vh 60vh 10vh;
  //grid-template-rows: 6fr 20fr 6fr 60fr 10fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'pre-title'
    'bunbury'
    'subtitle'
    'christian'
    'arrow';

  /* grid-template-rows: 10fr 10fr 10fr 50fr 10fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    ". pre-title pre-title ."
    ". bunbury bunbury ."
    ". subtitle subtitle ."
    ". christian christian ."
  ; */

  h1 {
    margin: 0;
    grid-area: bunbury;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: grid;
    justify-items: center;
    background-image: url('/Bunbury.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    color: transparent;
  }

  h2 {
    margin: 0;
  }

  .pre-title {
    font-size: 1.2em;
    grid-area: pre-title;
    text-align: center;
    font-weight: normal;
    margin: 2vh 0;

    em {
      font-style: normal;
      font-weight: bolder;
    }
  }

  .subtitle {
    margin: 0;
    grid-area: subtitle;
    font-weight: normal;

    aside {
      display: inline-block;
      margin-left: 0.5em;
      font-family: 'Pristina', sans-serif;
    }
  }

  img {
    max-width: 70vw;
    max-height: 100%;
    width: auto;
    height: auto;
    grid-area: christian;
  }

  .arrow {
    grid-area: arrow;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    fill: #ffffff;
    stroke: #ffffff;
    cursor: pointer;
  }

  #comingSoon {
    display: none;
    max-width: 100%;
    max-height: 100%;
    /* font-weight: bold; */
    font-size: 1.4em;
    text-align: center;
    font-style: normal;
  }
}

@media (min-width: 1100px) {
  #startScreen {
    /* grid-template-rows: 10fr 10fr 10fr 60fr; */
    /* grid-template-columns: 1fr;
    grid-template-areas:
      "pre-title"
      "bunbury"
      "subtitle"
      "christian"
    ; */
    /* grid-template-rows: 10fr 10fr 10fr 60fr;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas:
      ". pre-title ."
      ". bunbury ."
      ". subtitle ."
      "dates christian contact"
    ; */
  }
}

@media print {
  #comingSoon {
    grid-area: coming-soon;
    display: initial;
  }

  #startScreen {
    page-break-after: always;
    grid-template-rows: 6fr 20fr 6fr 60fr 10fr;
    grid-template-areas:
      'pre-title'
      'bunbury'
      'subtitle'
      'christian'
      'coming-soon';

    .subtitle {
      text-align: center;
      margin: 2vh 0;
    }
  }
}
</style>
