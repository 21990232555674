<template>
  <Screen class="projects">
    <Tile class="general" route="/">
      <span>Wir sind</span>
      <h1>Der ChaosTRUB</h1>
      <span>und das hier sind unsere bisherigen Projekte</span>
      <EmailLink class="email" stop-propagation />
    </Tile>
    <Tile
      class="bunbury"
      img="/Gruppenbild_Bunbury_resized.png"
      route="/bunbury"
      title="Bunbury nach Oscar Wilde (2019)"
      background-position="50% 0"
    />
    <Tile
      class="krug"
      img="/Gruppenbild_Krug_resized.png"
      route="krug"
      title="Der zerbrochene Krug nach Heinrich von Kleist (2020)"
      background-position="50% -20%"
    />
    <Tile
      class="minecraft"
      img="/MZ-Minecraft.png"
      route="minecraft"
      title="Proben in Minecraft"
      background-position="50% 50%"
    />
  </Screen>

  <Screen class="rsvp">
    <aside>Weiter geht's (hoffentlich)</aside>
    <span>2021</span>
  </Screen>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Screen from '@/components/Screen.vue'
import Tile from '@/components/Tile.vue'
import EmailLink from '@/components/EmailLink.vue' // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    EmailLink,
    Tile,
    Screen,
  },
})
</script>

<style lang="scss" scoped>
* {
  font-family: Roboto, sans-serif;
}

.rsvp {
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-size: 5em;

  aside {
    font-size: 0.3em;
    margin-bottom: 1em;
  }
}

.projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'general bunbury' 'krug krug-video';

  justify-items: stretch;
  align-items: stretch;
  font-family: Roboto, sans-serif;

  .general {
    grid-area: general;
    font-size: 1.5em;
    text-align: center;
    display: grid;
    align-content: center;
    justify-content: center;

    .email {
      display: block;
      position: absolute;
      bottom: 1em;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .bunbury {
    grid-area: bunbury;
  }

  .krug {
    grid-area: krug;
  }

  .krug-video {
    grid-area: krug-video;
  }
}

@media (max-width: 970px) {
  .projects {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 50vh);
    grid-template-areas: 'general' 'bunbury' 'krug' 'krug-video';

    .general .email {
      position: relative;
      bottom: initial;
      left: initial;
      width: initial;
      margin-top: 2em;
    }
  }
}
</style>
