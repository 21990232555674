<template>
  <div class="play-card">
    <a
      class="image"
      :style="{
        backgroundImage: `url('${imageUrl}')`,
        backgroundSize: backgroundSize ?? 'contain',
      }"
      :href="videoLink"
      target="_blank"
    >
      <div class="overlay">
        <YouTubeLogo />
      </div>
    </a>
    <div class="content">
      <h3>{{ title }}</h3>
      <slot />
      <div class="controls">
        <slot name="extra-controls"></slot>
        <div>
          <Button class="more" outlined :href="moreLink" color="black">
            Mehr
          </Button>
          <Button
            class="youtube"
            outlined
            :href="videoLink"
            color="red"
            target="_blank"
          >
            <YouTubeLogo />
            Video
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'
import YouTubeLogo from '@/views/Beta/YouTubeLogo.vue'

export default defineComponent({
  name: 'PlayCard',
  components: { YouTubeLogo, Button },
  props: {
    title: String,
    moreLink: String,
    videoLink: String,
    imageUrl: String,
    backgroundSize: String,
  },
})
</script>

<style lang="scss" scoped>
@import '../../mixins';

.play-card {
  --padding: 32px;
  --border-radius: 30px;
  // Increase top border radius to hide the white background on the top completely.
  // The image will take care of the actually visible border radius.
  // This is to prevent a Chrome rendering bug:
  // https://stackoverflow.com/questions/16938437/white-corner-showing-on-black-box-with-border-radius
  border-radius: calc(var(--border-radius) + 2px)
    calc(var(--border-radius) + 2px) var(--border-radius) var(--border-radius);
  background-color: var(--white);
  color: var(--black);
  font-size: 20px;
  line-height: 28px;
  //overflow: hidden;
  max-width: 900px;

  @include respond-below(md) {
    --padding: 20px;
    --border-radius: 20px;
    font-size: 16px;
  }
}

.image {
  border-radius: var(--border-radius);
  background-color: black;
  padding-bottom: calc(9 / 16 * 100%);
  background-repeat: no-repeat;
  background-position: center;
  --opacity: 0;
  position: relative;
  display: block;
  font-size: inherit;

  .overlay {
    background-color: rgba(0, 0, 0, var(--opacity));
    display: grid;
    place-items: center;
    opacity: 0;
    transition: opacity 300ms ease;
    border-radius: inherit;
    // First part is the 16:9 thing from above; the 24/35 are the YouTube logo's dimensions.
    // 0.33 because logo has 33% width.
    padding: calc((9 / 16 - 24 / 35 * 0.33) * 50%) 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;

    /deep/ svg {
      width: 33%;
      height: auto;
    }
  }

  &:hover {
    --opacity: 0.5;

    .overlay {
      opacity: 1;
    }
  }
}

.content {
  padding: var(--padding);
}

h3 {
  font-size: 2em;
  line-height: 110%;
  font-weight: 700;
  margin-top: 0;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--padding);

  @include respond-below(sm) {
    flex-direction: column;
  }
}

/deep/ .controls div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--padding);
}

/deep/ .more,
/deep/ .youtube {
  font-weight: bold;
}

/deep/ .youtube {
  display: flex;
  gap: 8px;
  --youtube-triangle-color: var(--white);

  svg {
    width: 2em;
  }
}
</style>
