
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProfileCard',
  props: {
    profileImageSrc: String,
    hasLeft: Boolean,
    name: String,
  },
})
