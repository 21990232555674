
export default {
  name: 'EmailLink',
  props: {
    stopPropagation: Boolean,
  },
  setup: (props: { stopPropagation?: boolean }) => {
    const onClick = (event: MouseEvent) => {
      if (props.stopPropagation) {
        event.stopPropagation()
      }
    }
    const parts = ['info', '@', 'chaostrub', '.', 'd', 'e']
    const address = parts.join('')

    return {
      href: ['m', 'a', 'i', 'l', 't', 'o', ':'].join('') + address,
      address,
      onClick,
    }
  },
}
