<template>
  <div class="footer">
    <div class="image col"></div>
    <div class="col">
      <div class="content">
        <h2>Bis zum nächsten Mal</h2>
        <p>
          Der Startschuss für das nächste Stück ist bereits gefallen. Wer auf
          dem Laufenden bleiben möchte, kann uns gerne auf Instagram folgen.
          Allgemeine Fragen können uns auch gerne per Mail gestellt werden.
        </p>
        <div class="contact-buttons">
          <Button outlined href="mailto:info@chaostrub.de">
            <font-awesome-icon icon="envelope" />
            info@chaostrub.de
          </Button>
          <Button
            outlined
            href="https://www.instagram.com/chaostrub"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
            ChaosTRUB
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Footer',
  components: { Button },
})
</script>

<style lang="scss" scoped>
@import '../../mixins';

.footer {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: var(--black);

  @include respond-below(md) {
    flex-direction: column;
  }
}

.col {
  width: 100%;

  @include respond-above(md) {
    flex-grow: 1;
  }
}

.image {
  background-image: url('/annika_grinst.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 20%;

  @include respond-below(md) {
    height: 50vh;
  }
}

.content {
  height: 100%;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 50px 100px 50px 50px;
  justify-content: center;
  max-width: 70ch;

  @include respond-below(md) {
    font-size: 18px;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @include respond-below(xs) {
    padding-left: 15vw;
    padding-right: 15vw;
  }
}

h2 {
  font-weight: 700;
  font-size: 72px;
  line-height: 84px;
  margin: 0;

  @include respond-below(md) {
    font-size: 48px;
    line-height: 58px;
  }
}

p {
  line-height: 38px;
  margin: 0;

  @include respond-below(md) {
    line-height: 32px;
  }
}

.contact-buttons {
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 1em;

  @include respond-above(sm) {
    flex-direction: row;
  }
}
</style>
