<template>
  <div class="hero">
    <div class="background"></div>
    <div class="content">
      <div class="wrapper">
        <h1><span class="pre-title">Der</span> ChaosTRUB</h1>
        <p>
          Wir sind eine studentische Theatergruppe am Musischen Zentrum der
          Ruhr-Universität Bochum. Unser Ziel ist es, dass das Publikum mit
          einem Lacher nach Hause zu schicken.
        </p>
        <Button outlined class="action-button" @click="scrollDown">
          <font-awesome-icon icon="arrow-down" />
          Mehr über uns
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Hero',
  components: { Button },
  setup: () => {
    const scrollDown = () => {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      })
    }

    return { scrollDown }
  },
})
</script>

<style lang="scss" scoped>
@import '../../mixins';

.hero {
  --padding: 100px;
  min-height: 100vh;
  width: 100%;
  padding: var(--padding);
  font-size: 24px;
  line-height: 1.6em;
  z-index: 1;
  display: flex;

  @include respond-below(md) {
    --padding: 15vw;
  }

  @include respond-below(sm) {
    font-size: 20px;
  }
}

.background {
  position: absolute;
  inset: 0;
  --dimming-opacity: 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, var(--dimming-opacity)),
      rgba(0, 0, 0, var(--dimming-opacity))
    ),
    url('/hero_square.jpg');
  background-size: contain;
  background-position-x: calc(50% - 50vmin);
  background-position-y: bottom;
  background-repeat: no-repeat;
  transform: scaleX(-1);
  z-index: 0;

  @include respond-below(md) {
    background-position: center 45%;
    background-size: cover;
    --dimming-opacity: 0.5;
  }
}

.content {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @include respond-below(md) {
    width: 100%;
    justify-content: center;
  }

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 1.4em;
    z-index: 1;
    max-width: calc(1440px / 2 - var(--padding));

    /deep/ * {
      z-index: 1;
    }
  }
}

h1 {
  font-size: 4em;
  line-height: 100%;
  color: var(--white);
  margin: 0;

  @include respond-below(lg) {
    font-size: 3em;
  }
  @include respond-below(md) {
    font-size: 2.5em;
  }

  .pre-title {
    display: block;
    font-size: 0.6em;
    line-height: 100%;
  }
}

p {
  margin: 0;
}

/deep/ .button.action-button {
  z-index: 1;
  width: fit-content;
}

/deep/ .fa-arrow-down {
  margin-right: 0.3em;

  @media (prefers-reduced-motion: no-preference) {
    //animation: 1s linear infinite vertical-hover;
    animation-name: vertical-hover;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    --oscillation: 2px;
  }
}

@keyframes vertical-hover {
  0% {
    transform: translateY(calc(1 * var(--oscillation)));
  }
  100% {
    transform: translateY(calc(-1 * var(--oscillation)));
  }
}
</style>
