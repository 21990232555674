
import { defineComponent } from 'vue'
import Members from '@/views/Beta/Members.vue'
import Footer from '@/views/Beta/Footer.vue'
import Hero from '@/views/Beta/Hero.vue'
import Plays from '@/views/Beta/Plays.vue'

export default defineComponent({
  name: 'Beta',
  components: { Plays, Footer, Members, Hero },
})
