<template>
  <div
    :class="{
      screen: true,
      strict: this.strict,
      [this.class]: this.class && !this.strict,
    }"
  >
    <div v-if="strict" :class="this.class">
      <slot></slot>
    </div>
    <slot v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Screen',
  props: {
    strict: {
      type: Boolean,
      default: false,
    },
    class: String,
  },
})
</script>

<style lang="scss" scoped>
.screen {
  min-height: 100vh;

  &.strict {
    display: block;
    height: 100vh;
    max-height: 100vh;
  }
}
</style>
