<template>
  <div class="wrapper">
    <h2>Mitglieder</h2>
    <div class="members">
      <ProfileCard
        class="yannic"
        name="Yannic"
        profile-image-src="/profile_images/yannic.jpg"
      >
        <template v-slot:roles>
          Algernon Moncrieff,<br />
          Ruprecht Tümpel,<br />
          Florindo Aretusi
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:yannic@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" />
          </a>
        </template>
      </ProfileCard>
      <ProfileCard
        name="Michael"
        class="michael"
        profile-image-src="/profile_images/michael.jpg"
        has-left
      >
        <template v-slot:roles>
          James / Lane,<br />
          Marthe Rull<br />
          -
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:michael@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" />
          </a>
          <a href="http://michaelkuckuk.com" target="_blank">
            <font-awesome-icon icon="globe" />
          </a>
          <a href="https://twitter.com/LBBO_" target="_blank">
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
        </template>
      </ProfileCard>
      <ProfileCard
        class="anni"
        name="Anni"
        profile-image-src="/profile_images/anni.webp"
      >
        <template v-slot:roles>
          - <br />
          Regieassistenz,<br />
          Beatrice Rasponi
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:anni@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" />
          </a>
        </template>
      </ProfileCard>

      <ProfileCard
        name="Christian"
        class="christian"
        profile-image-src="/profile_images/christian.jpg"
      >
        <template v-slot:roles>
          James Worthing,<br />
          Walter,<br />
          Silvio Lombardi
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:christian@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>

      <ProfileCard
        name="Johanna"
        class="johanna"
        profile-image-src="/profile_images/johanna.jpg"
      >
        <template v-slot:roles>
          Miss Prism,<br />
          Eva Rull,<br />
          Blandina
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:johanna@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>

      <ProfileCard
        class="liam"
        name="Liam"
        profile-image-src="/profile_images/liam.jpg"
      >
        <template v-slot:roles>
          - <br />
          Richter Adam,<br />
          Truffaldino Battacchio
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:liam@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>

      <ProfileCard
        class="nicola"
        name="Nicola"
        profile-image-src="/profile_images/nicola.jpg"
        has-left
      >
        <template v-slot:roles>
          Lady Bracknell<br />
          -<br />
          -
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:nicola@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>

      <ProfileCard
        name="Vanessa"
        class="vanessa"
        profile-image-src="/profile_images/vanessa.jpg"
      >
        <template v-slot:roles>
          Miss Fairfax,<br />
          Waltraud,<br />
          Rosaura
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:vanessa@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>

      <ProfileCard
        name="Annika"
        class="annika"
        profile-image-src="/profile_images/annika.jpg"
      >
        <template v-slot:roles>
          Dr. Chasuble,<br />
          Licht,<br />
          Nicoletta
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:annika@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>

      <ProfileCard
        name="Inga"
        class="inga"
        profile-image-src="/profile_images/inga.jpg"
        has-left
      >
        <template v-slot:roles>
          Cecily Cardew,<br />
          Gitte,<br />
          -
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:inga@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" />
          </a>
        </template>
      </ProfileCard>

      <ProfileCard
        name="Alida"
        class="alida"
        profile-image-src="/profile_images/alida.webp"
        has-left
      >
        <template v-slot:roles>
          -<br />
          -<br />
          Pandolfa
        </template>
        <template v-slot:social-media-links>
          <a href="mailto:alida@chaostrub.de" target="_blank">
            <font-awesome-icon icon="envelope" /> </a
        ></template>
      </ProfileCard>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProfileCard from '@/views/Beta/ProfileCard.vue'

export default defineComponent({
  name: 'Members',
  components: { ProfileCard },
})
</script>

<style lang="scss" scoped>
@import '../../mixins';

.wrapper {
  padding: 5em 3em;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(max-content, 900px);
  place-content: center;
  place-items: center;
  gap: 60px;
  background-color: var(--dark-gray);

  @include respond-below(md) {
    padding: 5em 10vw;
  }

  @include respond-below(sm) {
    padding: 5em 15vw;
  }
}

h2 {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}

.members {
  --gap: 50px;
  gap: var(--gap);

  justify-self: stretch;

  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(6, 1fr);
  place-items: stretch;
  place-content: center;
  //width: max-content;
  grid-template-areas:
    'alida alida anni anni annika annika'
    'christian christian inga inga johanna johanna'
    'liam liam michael michael nicola nicola'
    '. vanessa vanessa yannic yannic .';

  @include respond-below(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'alida anni'
      'annika christian'
      'inga johanna'
      'liam michael'
      'nicola vanessa'
      'yannic';
  }

  @include respond-below(sm) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'alida'
      'anni'
      'annika'
      'christian'
      'inga'
      'johanna'
      'liam'
      'michael'
      'nicola'
      'vanessa'
      'yannic';
  }
}

.michael {
  grid-area: michael;
}

.yannic {
  grid-area: yannic;
}

.anni {
  grid-area: anni;
}

.christian {
  grid-area: christian;
}

.johanna {
  grid-area: johanna;
}

.vanessa {
  grid-area: vanessa;
}

.annika {
  grid-area: annika;
}

.inga {
  grid-area: inga;
}

.nicola {
  grid-area: nicola;
}

.liam {
  grid-area: liam;
}

.alida {
  grid-area: alida;
}

.row {
  display: flex;
  flex-direction: row;
  gap: var(--gap);
  justify-content: center;

  @include respond-below(md) {
    flex-direction: column;
  }
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  text-decoration: none;
  color: inherit;
}
</style>
