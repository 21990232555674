<template>
  <div class="root-wrapper">
    <Hero />
    <Plays />
    <Members />
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Members from '@/views/Beta/Members.vue'
import Footer from '@/views/Beta/Footer.vue'
import Hero from '@/views/Beta/Hero.vue'
import Plays from '@/views/Beta/Plays.vue'

export default defineComponent({
  name: 'Beta',
  components: { Plays, Footer, Members, Hero },
})
</script>

<style lang="scss">
:root {
  --black: black;
  --dark-gray: #221d1c;
  --light-gray: #4d4d56;
  --white: #fdfffc;
  --red: red;
  // Desktop designs: 1440px; padding: 100px
  --max-content-width: #{1440 - 2 * 100}px;
}
</style>

<style lang="scss" scoped></style>
