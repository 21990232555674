
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Hero',
  components: { Button },
  setup: () => {
    const scrollDown = () => {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      })
    }

    return { scrollDown }
  },
})
