import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Krug from '../views/Krug/Krug.vue'
import Bunbury from '../views/Bunbury/Bunbury.vue'
import Minecraft from '../views/Minecraft/Minecraft.vue'
import Beta from '@/views/Beta/Home.vue'
import Diener from '@/views/Diener/Diener.vue'

const propsForRedirect = (URL: string) => ({
  beforeEnter: () => {
    window.location.href = URL
  },
  component: Home,
})

const routes: Array<RouteRecordRaw> = [
  {
    path: '/old',
    name: 'Alte Website',
    component: Home,
    meta: {
      title: 'Der ChaosTRUB',
    },
  },
  {
    path: '/beta',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Home',
    component: Beta,
    meta: {
      title: 'Der ChaosTRUB',
    },
  },
  {
    path: '/krug',
    name: 'Der zerbrochene Krug',
    component: Krug,
    meta: {
      title: 'Der zerbrochene Krug',
    },
  },
  {
    path: '/bunbury',
    name: 'Bunbury',
    component: Bunbury,
    meta: {
      title: 'Bunbury',
    },
  },
  {
    path: '/minecraft',
    name: 'Proben in Minecraft',
    component: Minecraft,
    meta: {
      title: 'Proben in Minecraft',
    },
  },
  {
    path: '/diener',
    name: 'Der Diener zweier Herren',
    component: Diener,
    meta: {
      title: 'Der Dienr zweier Herren',
    },
  },
  {
    path: '/krug-video',
    ...propsForRedirect('https://www.youtube.com/watch?v=p-iHh7Qfa6M'),
  },
  {
    path: '/youtube',
    ...propsForRedirect(
      'https://www.youtube.com/channel/UCFNnk_P24NxMmwgKLg487eg',
    ),
  },
  {
    path: '/facebook',
    ...propsForRedirect('https://www.facebook.com/events/630207527467873/'),
  },
  {
    path: '/trailer',
    ...propsForRedirect('https://www.youtube.com/watch?v=xsSRHFn-SgQ'),
  },
  {
    path: '/klageschrift',
    ...propsForRedirect('https://www.youtube.com/watch?v=dQw4w9WgXcQ'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
