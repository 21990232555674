<template>
  <Screen class="startScreen" strict>
    <h4 class="pre-title">
      Der <em>ChaosTRUB</em> präsentiert diesen Freitag und Samstag
    </h4>
    <h1>Der zerbrochene Krug</h1>
    <h4 class="subtitle">nach Heinrich von Kleist</h4>
    <img
      src="MLiam%20Cyrus.png"
      id="liam-cyrus"
      alt="A main character sitting in a black leather bag"
    />
    <NextScreenArrow class="arrow" />
    <em id="comingSoon">Diesen Freitag und Samstag im MZ!</em>
  </Screen>
</template>
<script lang="ts">
import NextScreenArrow from '@/components/NextScreenArrow.vue'
import Screen from '@/components/Screen.vue'

export default {
  name: 'StartScreen',
  components: { Screen, NextScreenArrow },
}
</script>
<style lang="scss">
// TODO: make scoped!

.startScreen {
  height: 100vh;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-rows: 1fr auto 2fr 10fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'pre-title' 'bunbury' 'subtitle' 'christian';

  h2 {
    margin: 0;
  }

  .arrow {
    $width: 7vw;
    width: $width;
    height: auto;
    fill: #ffffff;
    stroke: #ffffff;
    cursor: pointer;
    position: absolute;
    bottom: 3vh;
    /*right: 2vw;*/
    right: #{(100vw - $width) / 2};
  }

  .pre-title {
    /*font-size: 1.2em;*/
    grid-area: pre-title;
    text-align: center;
    font-weight: normal;
    margin: 0;
    align-self: end;

    em {
      font-style: normal;
      font-weight: bolder;
    }
  }

  h1 {
    margin: 0;
    grid-area: bunbury;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    font-size: calc(3rem + 2vw);
    text-align: center;

    object {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .subtitle {
    margin: 0;
    grid-area: subtitle;
    font-weight: normal;
    align-self: start;

    aside {
      display: inline-block;
      margin-left: 0.5em;
      font-family: 'Pristina', sans-serif;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    /*width: 100vw;*/
    grid-area: christian;
    align-self: end;
  }
}

#comingSoon {
  display: none;
  grid-area: coming-soon;
  max-width: 100%;
  max-height: 100%;
  /* font-weight: bold; */
  font-size: 1.4em;
  text-align: center;
  font-style: normal;
}

@media (max-width: 1100px) {
  .startScreen {
    h1 {
      font-size: calc(4rem);
    }

    h4 {
      font-size: 1.8em;
    }
  }
}

@media print {
  .startScreen {
    page-break-after: always;
    grid-template-rows: 2fr 4fr 6fr 4fr 50fr; /* 10fr;*/
    grid-template-areas: '.' 'pre-title' 'bunbury' 'subtitle' 'christian';

    .pre-title,
    .subtitle {
      font-size: 1.5em;
    }

    img {
      width: 90vw;
    }

    .subtitle {
      text-align: center;
    }
  }

  h1 {
    font-size: 4.3em;
  }
}
</style>
