
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'
import YouTubeLogo from '@/views/Beta/YouTubeLogo.vue'

export default defineComponent({
  name: 'PlayCard',
  components: { YouTubeLogo, Button },
  props: {
    title: String,
    moreLink: String,
    videoLink: String,
    imageUrl: String,
    backgroundSize: String,
  },
})
