
export default {
  name: 'Tile',
  props: {
    img: String,
    route: String,
    title: String,
    backgroundPosition: String,
    overlayOpacity: Number,
  },
}
