<template>
  <main>
    <StartScreen />
    <Screen id="trailer">
      <iframe
        src="https://www.youtube-nocookie.com/embed/yjfnVfG78rE"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </Screen>
    <InfoScreen />
  </main>
</template>

<script lang="ts">
import Screen from '@/components/Screen.vue'
import StartScreen from '@/views/Bunbury/StartScreen.vue'
import InfoScreen from '@/views/Bunbury/InfoScreen.vue'

export default {
  name: 'Bunbury',
  components: { InfoScreen, StartScreen, Screen },
}
</script>

<style lang="scss">
@font-face {
  font-family: Pristina;
  src: url('/PRISTINA.TTF');
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #000000;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}
</style>

<style lang="scss" scoped>
/*
 * TRAILER
 */

#trailer {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
}

#trailer iframe {
  width: 100%;
  height: 100%;
}

@media print {
  html,
  body {
    background-color: #000000 !important;
  }

  #arrow,
  #trailer {
    display: none;
  }
}
</style>
