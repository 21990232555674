<template>
  <div class="tile">
    <router-link :to="route">
      <div
        v-if="img"
        class="img"
        :style="{
          backgroundImage: `url(${img})`,
          backgroundPosition,
        }"
      >
        <slot />
      </div>
      <slot v-else />
      <div
        v-if="title"
        class="overlay"
        :style="{
          background: `rgba(0, 0, 0, ${overlayOpacity ?? 0.45})`,
        }"
      >
        {{ title }}
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Tile',
  props: {
    img: String,
    route: String,
    title: String,
    backgroundPosition: String,
    overlayOpacity: Number,
  },
}
</script>

<style lang="scss" scoped>
.tile {
  align-content: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    .img {
      transform: scale(1.05);
    }
  }

  a,
  a:focus,
  a:active,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  .img {
    width: 100%;
    height: 100%;
    transition: 0.5s;
    color: #ebebeb;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .overlay {
    position: absolute;
    transition: 0.5s;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    justify-content: center;
    align-content: center;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    padding: 1.5em;
  }
}
</style>
