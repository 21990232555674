<template>
  <a :href="href" @click="onClick">
    {{ address }}
  </a>
</template>

<script lang="ts">
export default {
  name: 'EmailLink',
  props: {
    stopPropagation: Boolean,
  },
  setup: (props: { stopPropagation?: boolean }) => {
    const onClick = (event: MouseEvent) => {
      if (props.stopPropagation) {
        event.stopPropagation()
      }
    }
    const parts = ['info', '@', 'chaostrub', '.', 'd', 'e']
    const address = parts.join('')

    return {
      href: ['m', 'a', 'i', 'l', 't', 'o', ':'].join('') + address,
      address,
      onClick,
    }
  },
}
</script>

<style lang="scss" scoped>
a,
a:visited,
a:focus,
a:active {
  color: inherit;
}
</style>
