
import { defineComponent } from 'vue'
import Screen from '@/components/Screen.vue'
import Tile from '@/components/Tile.vue'
import EmailLink from '@/components/EmailLink.vue' // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    EmailLink,
    Tile,
    Screen,
  },
})
