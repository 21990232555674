<template>
  <div class="profile-card" :class="hasLeft ? 'has-left' : ''">
    <div
      class="image"
      :style="{ backgroundImage: `url(${profileImageSrc})` }"
      :alt="'Foto von ' + name"
    />
    <h3 class="name">
      {{ name }}
    </h3>
    <div class="roles">
      <slot name="roles"></slot>
    </div>
    <div class="social-media-links">
      <slot name="social-media-links"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProfileCard',
  props: {
    profileImageSrc: String,
    hasLeft: Boolean,
    name: String,
  },
})
</script>

<style lang="scss" scoped>
.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  //width: 266px;

  font-size: 24px;
  line-height: 160%;

  padding: 1em 1.5em;

  background: var(--white);
  color: var(--black);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 30px;

  text-align: center;

  &.has-left::after {
    content: '';
    background-size: contain;
    background: url('/wasted.png') no-repeat 50% 50%;
    background-size: contain;
    transform: rotate(32.5deg);
    width: 80%;
    height: 30%;
    position: absolute;
    top: 28%;
  }
}

.image {
  border-radius: 100%;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 1em;
}

.name {
  margin: 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
}

.roles {
  font-weight: 400;
  font-style: italic;
  font-size: 20px;
  line-height: 32px;
  color: var(--light-gray);

  margin-top: 10px;
  margin-bottom: 50px;
  flex-grow: 1;
}

.social-media-links {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}
</style>
