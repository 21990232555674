<template>
  <Screen id="info">
    <div id="description">
      <article>
        <h2>
          <object
            data="Bunbury.svg"
            alt="Bunbury"
            type="image/svg+xml"
          ></object>
        </h2>
        <h3 class="subtitle">
          Die Bedeutung, Ernst zu sein
          <aside>nach Oscar Wilde</aside>
        </h3>

        <p>
          Seien Sie mal Ernst: Fällt es Ihnen auch manchmal schwer Ernst zu
          sein? Ernst in einer anderen Stadt, in einem anderen Umfeld? Ernst bei
          Ihrem Bachelor? Ernst in der Ernsthaftigkeit?
        </p>
        <p>
          „Bunbury - Die Bedeutung, Ernst zu sein“ von Oscar Wilde wird Ihnen
          bei diesen Fragen mit dem gewissen Ernst zur Seite stehen. Wir leben
          in einer Zeit der Ernsthaftigkeit, also lassen Sie uns gemeinsam damit
          aufhören, Ernst zu sein.
        </p>
      </article>
    </div>
    <div id="cast">
      <h2>Besetzung</h2>
      <ul>
        <li>
          <span class="filler"></span>
          <span class="character">Mr. Worthing</span>
          <span class="actor">Christian Haak</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Mr. Moncrieff</span>
          <span class="actor">Yannic Säck</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Miss Cardew</span>
          <span class="actor">Inga Schauenburg</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Miss Fairfax</span>
          <span class="actor">Vanessa Bliecke</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Lady Bracknell</span>
          <span class="actor">Nicola Hunfeld</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Mr. Chasuble</span>
          <span class="actor">Annika Fischer</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Miss Prism</span>
          <span class="actor">Johanna Wrede</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Lane / James</span>
          <span class="actor">Michael Kuckuk</span>
        </li>
        <br />
        <!-- <li>
            <span class="filler"></span>
            <span class="character"></span>
            <span class="actor"></span>
        </li> -->
        <li>
          <span class="filler"></span>
          <span class="character">Kostüme</span>
          <span class="actor">Regina Leyra Galan</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Technik</span>
          <span class="actor">Anna Metzler</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Trailer</span>
          <span class="actor">Kai Bernhardt</span>
        </li>
      </ul>
    </div>
    <div id="logos">
      <img
        src="studiobuehne.jpg"
        class="logo studiobuehne"
        alt="The logo of the Studiobühne Bochum"
      />
      <div class="logo trailer">
        <!-- <h4>Trailer</h4> -->
        <img src="qr_bunbury.svg" alt="QR-Code to our trailer" />
        <!-- <object data="./qr.svg" alt="QR-Code to our trailer"  type="image/svg+xml"></object> -->
      </div>
      <img
        src="akafoe.png"
        class="logo akafoe"
        alt="The logo of the AKAfÖ Bochum"
      />
    </div>
    <aside id="dates">
      <span>Aufführungstermine:</span>
      <ol>
        <li>
          <span class="filler"></span><span class="weekday">Freitag,</span>
          <span class="dateAndTime">11.01.2019 - 19:30 Uhr</span>
        </li>
        <li>
          <span class="filler"></span><span class="weekday">Samstag,</span>
          <span class="dateAndTime">12.01.2019 - 19:30 Uhr</span>
        </li>
      </ol>
      <span>im Musischen Zentrum der RUB</span>
    </aside>
    <aside id="contact">
      <span>Infos unter <a href="#" title="chaostrub.de">chaostrub.de</a></span>
      <span>Reservierungen an</span>
      <span><a class="email"></a></span>
      <span><em>Eintritt frei</em></span>
    </aside>
  </Screen>
</template>
<script lang="ts">
import Screen from '@/components/Screen.vue'

export default {
  name: 'InfoScreen',
  components: { Screen },
}
</script>
<style lang="scss" scoped>
/*
 * INFO
 */

#info {
  height: 90vh;
  display: grid;
  grid-template-areas:
    'description description'
    'cast cast'
    'dates contact';
  grid-template-rows: 3fr 4fr 3fr;
  margin: 5vh 5vw;
}

#description {
  grid-area: description;

  h2 {
    display: grid;
    justify-items: center;

    object {
      max-width: 30%;
      max-height: 100%;
    }
  }

  .subtitle {
    margin: 0;
    grid-area: subtitle;
    font-size: 1em;
    justify-self: center;
    text-align: center;

    aside {
      display: inline-block;
      margin-left: 0.4em;
      font-family: 'Pristina';
    }
  }
}

#cast {
  grid-area: cast;
  justify-items: stretch;

  h2 {
    text-align: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'character filler actor';
    line-height: 1.4em;
  }

  .character {
    grid-area: character;
  }

  .actor {
    grid-area: actor;
  }

  .filler {
    grid-area: filler;
    border-bottom: 2px dotted #ffffff;
    margin: 0 5px;
    height: calc(100% - 0.4em);
  }
}

#dates,
#contact {
  font-size: calc(2 * (1vw + 1vh) / 2);
}

#dates {
  grid-area: dates;
  align-self: end;
  justify-self: start;

  & > span {
    display: block;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ol li {
    display: block;
    font-weight: bold;
  }
}

#contact {
  grid-area: contact;
  display: grid;
  align-self: end;
  justify-self: end;
  justify-content: end;

  & > span {
    display: block;
    text-align: end;

    em {
      font-style: normal;
      font-weight: bolder;
    }
  }

  a,
  a:link,
  a:hover,
  a:active,
  a:visited,
  a:-webkit-any-link {
    color: #ffffff !important;
    text-decoration: none;
    font-weight: bold;
  }
}

#logos {
  display: none;
}

@media (min-width: 1100px) {
  #info {
    font-size: 1.3em;
    grid-template-areas:
      'description cast'
      'dates contact';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4vw;
    grid-template-rows: auto auto;
  }

  #cast h2 {
    margin-block-start: calc((5vw + 5vh) / 2);
    margin-block-end: calc((5vw + 5vh) / 2);
  }
}

@media print {
  #info {
    font-size: 0.8em;
    grid-template-areas:
      'description description'
      'cast cast'
      'logos logos'
      'dates contact';
    grid-template-rows: 2fr 2fr 2fr 1fr;
  }

  #logos {
    grid-area: logos;
    display: grid;
    grid-template-areas: 'studiobuehne akafoe trailer';
    grid-template-columns: 1fr 1fr 1fr;
    align-content: space-around;
    grid-gap: 15vw;
    align-items: center;
    justify-items: center;

    * {
      display: initial;
      max-width: 100%;
      /* width: 100%; */
      max-height: 100%;
      /* height: 100%; */
    }

    .studiobuehne {
      grid-area: studiobuehne;
    }

    .trailer {
      grid-area: trailer;
    }

    .trailer h4 {
      text-align: center;
      width: 100%;
      font-size: 2em;
    }

    .akafoe {
      grid-area: akafoe;
    }
  }

  #cast .filler {
    border-bottom-width: 1px;
  }
}
</style>
