<template>
  <router-view />
</template>

<script lang="ts">
import type { RouteRecordRaw } from 'vue-router'

export default {
  name: 'App',
  watch: {
    $route(to: RouteRecordRaw) {
      document.title = to?.meta?.title || 'Der ChaosTRUB'
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto');

html,
body {
  background-color: #000000;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}
</style>
