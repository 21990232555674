<template>
  <svg viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.2791 3.75529C33.8755 2.28706 32.6934 1.12941 31.1943 0.734118C28.4554 0 17.4999 0 17.4999 0C17.4999 0 6.54445 0 3.80559 0.705882C2.33525 1.10118 1.12438 2.28706 0.720755 3.75529C0 6.43765 0 12 0 12C0 12 0 17.5906 0.720755 20.2447C1.12438 21.7129 2.30642 22.8706 3.80559 23.2659C6.57328 24 17.4999 24 17.4999 24C17.4999 24 28.4554 24 31.1943 23.2941C32.6934 22.8988 33.8755 21.7412 34.2791 20.2729C34.9999 17.5906 34.9999 12.0282 34.9999 12.0282C34.9999 12.0282 35.0287 6.43765 34.2791 3.75529Z"
      class="rectangle"
    />
    <path
      d="M23.1216 12L14.0112 6.86121V17.1389L23.1216 12Z"
      class="triangle"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'YouTubeLogo',
  props: {
    rectangleColor: String,
    triangleColor: String,
  },
})
</script>

<style lang="scss">
:root {
  --youtube-rectangle-color: #ff0000;
  --youtube-triangle-color: #fff;
}
</style>

<style lang="scss" scoped>
.rectangle {
  fill: var(--youtube-rectangle-color);
}

.triangle {
  fill: var(--youtube-triangle-color);
}
</style>
