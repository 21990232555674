
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Screen',
  props: {
    strict: {
      type: Boolean,
      default: false,
    },
    class: String,
  },
})
