<template>
  <Screen id="info">
    <div id="description">
      <h1>
        Der zerbrochene Krug
        <aside>nach Heinrich von Kleist</aside>
      </h1>
      <!--				<h3 class="subtitle">nach Heinrich von Kleist</h3>-->

      <h4>
        Sehr geehrte Nachbarn, liebe Freunde und Verwandte, verehrter Herr
        Bürgermeister,
      </h4>
      <p>
        mir schmerzt es sehr, verkünden zu müssen, dass gestern Abend das
        Undenkbare eingetreten ist: Mein Krug wurde zertrümmert, und zwar
        offensichtlich von dem Drecksbengel Ruprecht Tümpel.
      </p>
      <p>
        Am 10. und 11. Januar werde ich ihn deshalb vor dem Gericht des
        Musischen Zentrums zur Verantwortung ziehen. Sie sind alle herzlich
        eingeladen, sich ab 19:30 Uhr kostenlos anzusehen, wie die Gerechtigkeit
        ihren Lauf nimmt.
      </p>
      <p>
        Hochachtungsvoll (außer, Sie heißen Ruprecht Tümpel)<br />
        Marthe Rull
      </p>
    </div>
    <div id="cast">
      <h2>Besetzung</h2>
      <ul>
        <li>
          <span class="filler"></span>
          <span class="character">Richter Adam</span>
          <span class="actor">Liam Kranz</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Gerichtsrat Walter</span>
          <span class="actor">Christian Haak</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Gerichtsrätin Waltraud</span>
          <span class="actor">Vanessa Bliecke</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Schreiberin Licht</span>
          <span class="actor">Annika Fischer</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Marthe Rull</span>
          <span class="actor">Michael Kuckuk</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Eva Rull</span>
          <span class="actor">Johanna Wrede</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Ruprecht Tümpel</span>
          <span class="actor">Yannic Säck</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Gerichtsdienerin Gitte</span>
          <span class="actor">Inga Schauenburg</span>
        </li>
        <br />
        <!-- <li>
          <span class="filler"></span>
          <span class="character"></span>
          <span class="actor"></span>
        </li> -->
        <li>
          <span class="filler"></span>
          <span class="character">Krug</span>
          <span class="actor">Ralf D'Atri</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Technik</span>
          <span class="actor">Christoph Ranft</span>
        </li>
        <li>
          <span class="filler"></span>
          <span class="character">Projektassistenz</span>
          <span class="actor">Annika Sengalski</span>
        </li>
      </ul>
    </div>
    <div id="logos">
      <img
        src="studiobuehne_white_background.jpg"
        class="logo studiobuehne"
        alt="The logo of the Studiobühne Bochum"
      />
      <div class="logo trailer">
        <!-- <h4>Trailer</h4> -->
        <img src="qr_krug_white_background.svg" alt="QR-Code to our trailer" />
        <!-- <object data="./qr.svg" alt="QR-Code to our trailer"  type="image/svg+xml"></object> -->
      </div>
      <img
        src="akafoe.png"
        class="logo akafoe"
        alt="The logo of the AKAfÖ Bochum"
      />
    </div>
    <aside id="dates">
      <span>Aufführungstermine:</span>
      <ol>
        <li>
          <span class="filler"></span>
          <span class="weekday">Freitag,</span>
          <span class="dateAndTime">10.01.2020 - 19:30 Uhr</span>
        </li>
        <li>
          <span class="filler"> </span><span class="weekday">Samstag,</span>
          <span class="dateAndTime">11.01.2020 - 19:30 Uhr</span>
        </li>
      </ol>
      <span>im Musischen Zentrum der RUB</span>
    </aside>
    <aside id="contact">
      <span
        >Infos & Trailer:
        <a href="#" title="chaostrub.de">chaostrub.de</a></span
      >
      <span>Reservierungen an</span>
      <span><EmailLink /></span>
      <span><em>Eintritt frei</em></span>
    </aside>
  </Screen>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Screen from '@/components/Screen.vue'
import EmailLink from '@/components/EmailLink.vue'

export default defineComponent({
  name: 'InfoScreen',
  components: { Screen, EmailLink },
})
</script>

<style lang="scss" scoped>
#info {
  display: grid;
  grid-template-areas: 'description description' 'cast cast' 'dates contact';
  grid-template-rows: 3fr 4fr 3fr;
  padding: 8vh 8vw;
  grid-gap: 2.5rem 0;

  #description {
    grid-area: description;

    h1 {
      text-align: center;
    }

    aside {
      font-size: 0.6em;
      display: block;
      font-weight: normal;
    }

    h4,
    p {
      font-family: 'Pristina';
      font-size: 1.2em;
      text-align: center;
    }
  }

  #cast {
    grid-area: cast;
    justify-items: stretch;
    font-size: 1.2em;

    h2 {
      text-align: center;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-template-areas: 'character filler actor';
      line-height: 1.5em;
    }

    .character {
      grid-area: character;
    }

    .actor {
      grid-area: actor;
    }

    .filler {
      grid-area: filler;
      border-bottom: 2px dotted #ffffff;
      margin: 0 5px;
      height: calc(100% - 0.4em);
    }
  }

  #dates,
  #contact {
    font-size: 1.2em;
  }

  #dates {
    grid-area: dates;

    & > span {
      display: block;
      text-align: center;
    }

    ol {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        display: block;
        font-weight: bold;
      }
    }
  }

  #contact {
    grid-area: contact;
    display: grid;
    align-self: end;
    justify-self: end;
    justify-content: end;

    & > span {
      display: block;
      text-align: end;

      em {
        font-style: normal;
        font-weight: bolder;
      }
    }

    a,
    a:link,
    a:hover,
    a:active,
    a:visited,
    a:-webkit-any-link {
      color: #ffffff !important;
      text-decoration: none;
      font-weight: bold;
    }
  }

  #dates {
    align-self: end;
    justify-self: start;

    ol {
      text-align: start;
    }

    & > span {
      text-align: start;
    }
  }

  #logos {
    display: none;
  }
}

@media (min-width: 1100px) {
  #info {
    font-size: 1.3em;
    grid-template-areas: 'description cast' 'dates contact';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4vw;
    grid-template-rows: auto auto;
  }

  #cast h2 {
    margin-block-start: calc((5vw + 5vh) / 2);
    margin-block-end: calc((5vw + 5vh) / 2);
  }
}

@media (max-width: 1100px) {
  #description h1 {
    font-size: calc(4rem);
  }

  #info {
    font-size: 1.8em;
    /*grid-template-areas: "description" "cast" "dates" "contacts";*/
    /*grid-template-columns: 1fr;*/
    /*grid-template-rows: auto auto auto;*/
    display: block;

    & > *:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  #contact {
    justify-content: center;

    span {
      text-align: center;
    }
  }

  #dates {
    & > span,
    & > ol {
      text-align: center;
    }
  }
}

@media print {
  #info {
    font-size: 0.8em;
    grid-template-areas: 'description description' 'cast cast' 'logos logos' 'dates contact';
    grid-template-rows: 4fr 6fr 4fr 3fr;

    background-color: #ffffff;
    color: #000000;
    grid-gap: 0;
  }

  #logos {
    grid-area: logos;
    display: grid;
    grid-template-areas: 'studiobuehne akafoe trailer';
    grid-template-columns: 1fr 1fr 1fr;
    align-content: space-around;
    grid-gap: 15vw;
    align-items: center;
    justify-items: center;

    * {
      display: initial;
      max-width: 100%;
      /* width: 100%; */
      max-height: 100%;
      /* height: 100%; */
    }

    .studiobuehne {
      grid-area: studiobuehne;
    }

    .trailer {
      grid-area: trailer;

      h4 {
        text-align: center;
        width: 100%;
        font-size: 2em;
      }
    }

    .akafoe {
      grid-area: akafoe;
    }
  }

  #cast .filler {
    border-bottom-width: 1px;
    border-color: #000000;
  }

  #contact a,
  #contact a:link,
  #contact a:hover,
  #contact a:active,
  #contact a:visited,
  #contact a:-webkit-any-link {
    color: #000000 !important;
  }
}
</style>
