<template>
  <main>
    <h1>Proben in Minecraft</h1>
    <article>
      <p>
        Nach einem Jahr ohne das Musische Zentrum haben wir beschlossen, dass
        wir diese Schließung einfach nicht länger dulden können. Daher haben wir
        das Musische Zentrum in Minecraft erbaut und proben von nun an auf der
        Bühne.
      </p>

      <p>
        Falls noch jemand gerne in Minecraft proben möchte, stellen wir gerne
        unsere Welt zur Verfügung. Downloadet sie einfach
        <a href="/Musisches Zentrum.zip" download>hier</a>
        und verwendet sie wie jede andere Minecraft Map auch. Wie das geht, kann
        man leicht auf YouTube herausfinden.
      </p>

      <p>
        In der Welt haben wir außerdem einen kleinen Teaser für unser kommendes
        Stück "Der Diener zweier Herren" gedreht. Viel Vergnügen beim Gucken!
      </p>
    </article>
    <div class="video-wrapper">
      <iframe
        src="https://www.youtube-nocookie.com/embed/vuhpkGldLAo"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Minecraft',
})
</script>

<style lang="scss" scoped>
main {
  display: grid;
  grid-auto-rows: auto;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  background-color: transparent;
  padding: 2em;

  * {
    z-index: 1;
  }

  &:before {
    z-index: 0;
    content: '';
    background-image: url('/MZ-Minecraft.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0.2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  h1 {
    width: max-content;
  }

  .video-wrapper {
    width: 100%;
    height: min-content;
    position: relative;
    display: flex;
    justify-items: stretch;
    align-items: stretch;

    &:before {
      content: '';
      float: left;
      padding-bottom: #{100% * 9 / 16};
    }

    iframe {
      align-self: stretch;
      width: 100%;
    }
  }

  article {
    max-width: 70ch;

    p {
      a,
      a:active,
      a:focus,
      a:visited {
        color: inherit;
        font-weight: bold;
      }
    }
  }
}
</style>
